<template>
  <div class="create-account">
    <div class="overlay"></div>
    <div class="create-account-content">
      <div class="create-account-header">
        <div class="create-account-header__left">
          <button
            class="create-account-header__back-button"
            type="button"
            @click="changeView('Login')"
          >
            <i
              class="ion ion-arrow-back-circle-outline create-account-header__back-button__icon"
              v-b-popover.hover.top="$t('createAccount.message.goBack')"
            ></i>
          </button>
        </div>
        <div class="create-account-header__image-container">
          <img
            class="create-account-header__image"
            src="https://shipwithglt.com/wp-content/uploads/2018/09/GLT-LOGISTICS-1.svg"
            alt="LOGO GLT"
          />
        </div>
        <div class="create-account-header__title-container">
          <span v-html="$t('createAccount.tittle')"></span>
        </div>
      </div>
      <b-overlay :show="loadingCreateAccount" rounded="sm">
        <CreateAccountInputs
          v-model="accountForm"
          @updateCheckedTerms="updateCheckedTerms"
          @updateEmailUpdates="updateEmailUpdates"
          :createAccountFromLogin="true"
          ref="create-account-inputs-data"
        />
        <div class="create-account-button">
          <button
            class="create-account-button__button"
            type="submit"
            @click="createAccount"
          >
            <p class="mb-0">
              {{ $t("createAccount.button") }}
            </p>
          </button>
          <p class="login-link-text">
            {{ $t("createAccount.info.first") }}
            <span class="login-link-text__link">
              <router-link to="/login" v-html="$t('createAccount.info.second')"></router-link>
            </span>
          </p>
        </div>
      </b-overlay>
    </div>
    <div class="create-account-brand">GLT logistics ® - 2022</div>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
import { generalMessage } from "@/utils/ErrorMessages";
import CreateAccountInputs from "./CreateAccountInputs.vue";
import errorValidation from "../../Extend/ValidationCreateAccount";

export default {
  name: "CreateAccount",
  components: {
    CreateAccountInputs,
  },
  mixins: [errorValidation],
  data() {
    return {
      accountForm: {
        name: "Name",
        company_name: "",
        company_address: "",
        phone: "",
        first_name: "",
        lastname: "",
        email: "",
        country_code: "",
        zip_code: "",
        city: "",
        state_code: "",
        tax_id: "",
        accounting: "",
        website: "",
        password: "",
        confirm_password: "",
      },
      email_updates: false,
      checkedTerms: false,
      loadingCreateAccount: false,
    };
  },
  methods: {
    async createAccount() {
      const isValidForm = await this.$refs["create-account-inputs-data"].validateForm();
      if (isValidForm) {
        this.loadingCreateAccount = true;
        const payload = { ...this.accountForm };
        payload.country_code = this.accountForm.country_code.name;
        payload.email_updates = this.email_updates;
        payload.accept_terms = this.checkedTerms;
        delete payload.accounts;
        const response = await this.$store.dispatch("user/createUser", payload);
        if (response !== undefined) {
          generalMessage("Your request is in process!");
          await this.$router.push({ name: "Login" });
        }
      }
      this.loadingCreateAccount = false;
    },
    updateCheckedTerms(value) {
      this.checkedTerms = value;
    },
    updateEmailUpdates(value) {
      this.email_updates = value;
    },
    changeView(view) {
      if (this.$route.name !== view) {
        this.$router.push({ name: view });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.create-account {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $font-family-portal;
  @include background-image(0%, 20%);
  background: url("../../assets/images/home-background.jpg");
  position: relative;
}

.overlay {
  @include background-image-overlay($color-primary-company, 0.85);
}

.create-account-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column !important;
  width: 100%;
  max-width: 95vw;
  margin-top: 2%;
  padding: 2% 4%;
  background-color: $color-white;
  border-radius: 30px;
  z-index: 1;
}

.create-account-header {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  &__image-container {
    width: 140px;
    border-right: 2px solid $color-blue-hover;
  }
  &__image {
    max-height: 65px;
    margin-right: 2%;
    @media (max-width: 545px) {
      max-height: 55px;
    }
    @media (max-width: 300px) {
      max-height: 45px;
    }
  }
  &__title-container {
    @include font-main-title;
    text-align: start;
    width: 125px;
    margin: 0 auto 0 2%;
    height: 100%;
    @media (max-width: 545px) {
      margin: 0 auto 0 4%;
    }
  }
  &__back-button {
    margin: 0 auto 0 0;
    @include go-back-button;
    &__icon {
      @include go-back-button-icon;
    }
  }
  &__left {
    margin: 0 auto 0 0;
  }
}

.create-account-brand {
  width: 100%;
  color: $color-white;
  margin-top: 1.5%;
  margin-bottom: 25px;
  font-style: italic;
  z-index: 1;
}
.brand-container {
  @include brand-quote($color-white);
  &__text {
    padding: 20px;
  }
}
.create-account-button {
  width: 100%;
  &__button {
    @include primary-button(40px);
    margin-top: 2%;
    width: 25%;
    @include font-button-text;
  }
  @media (max-width: 900px) {
    &__button {
      width: 40%;
    }
  }
  @media (max-width: 680px) {
    &__button {
      @include primary-button(48px);
      width: 60%;
      margin-top: 20px;
    }
  }
}
.login-link-text {
  margin-top: 1rem;
  color: $color-primary-company;
  &__link a {
    color: $color-blue-hover;
  }
}
</style>
